type errorsType = {
    [K: string]: {
        title: string;
        message: string;
    };
};

const errors: errorsType = {
    "404": {
        title: "404! Not Found",
        message: "Page Not Found!"
    },
    "403": {
        title: "403! Forbidden",
        message: "You do not have permission to access the content!"
    },
    "500": {
        title: "500! Internal Server Error",
        message:
            "Internal Server Error. The server has encountered a situation that it does not know how to handle."
    },
    "*": {
        title: "Unknown error!",
        message: ""
    }
};

export default errors;
